import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from "@mui/material";
import {
    QueryClient,
    QueryClientProvider
} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'

const CoreTheme = createTheme({
    palette: {
        background: {
            default: "#f5fdfe",
        }
    }
});

const queryClient = new QueryClient();


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={CoreTheme}>
            <QueryClientProvider client={queryClient}>
                <App/>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
